const menu = () => {

    const switcherMobile = document.querySelectorAll('.js-toggle');

    if (switcherMobile) {
        const switcherTarget = document.querySelector('.js-header');
        switcherMobile.forEach(el => {
            el.addEventListener('click', () => {
                switcherMobile.forEach(el2 => {
                    el2.classList.toggle('is-active');
                });
                switcherTarget.classList.toggle('is-active');
            });
        });
    }

    const linkScroll = document.querySelectorAll('.scroll-link-js');

    if (linkScroll) {
        linkScroll.forEach(link => {
            link.addEventListener('click', () => {
                switcherMobile.forEach(el => {
                    el.classList.remove('is-active')
                });
                document.querySelector('.js-header').classList.remove('is-active');
            });
        });
    }

    const fixedMenu = () => {
        const header = document.querySelector('.js-header');
        if (header) {
            const doc = document.documentElement;
            const checkScroll = () => {
                let curScroll = window.scrollY || doc.scrollTop;

                if (curScroll > 210) header.classList.add('is-fixed');
                else header.classList.remove('is-fixed');
            };

            window.addEventListener('scroll', checkScroll);
        }
    };

    fixedMenu();

}


/* All functions setting */

const init = () => {
    menu();
};

document.addEventListener('DOMContentLoaded', () => {
    init();
});

/* Cookie local storage */
document.addEventListener("DOMContentLoaded", function (event) {
    var cookiesContainer = document.querySelector('.cookie-bar');
    if (cookiesContainer) {
        var cookiesAccept = localStorage.getItem('cookiesAccept');
        if (cookiesAccept === 'true') {
            return;
        }
        cookiesContainer.classList.remove('hidden');
        var cookiesButton = document.querySelector('.cookie-accept-js');
        if (cookiesButton) {
            cookiesButton.addEventListener('click', function () {
                localStorage.setItem('cookiesAccept', 'true');
                console.log('dziala');
                cookiesContainer.classList.add('hidden');
            });
        }
    }
});

/* Init animation scripts */
if (typeof AOS !== 'undefined') {
    AOS.init();
}